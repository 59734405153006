body {
    background-color: #f3f4f6;
}

h1 {
    color: $primary-color;
    font-weight: 500;
    margin: 1.75rem 0 0 0;
    line-height:110%;
}
h2 {
    color: $primary-color;
    font-weight: 400;
}

h1 strong, h2 strong {
    font-weight: 700;
}

h1.product-name {
    font-size: 2.5rem;
    margin-top: 3rem !important;
    margin-bottom: 1rem !important;
}

h2.product-heading-2 {
    font-size: 2.8125rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 0;
    color: $primary-color;
}
#secproddescrip h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.mt1 {
    margin-top: 1rem;
}
.wrapper {
    width: 80%;
    margin: 0 auto;
}

@media ( max-width: 62em ) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .container {
        width: 95%;
        max-width: 62rem !important;
        margin: 0 auto !important;
    }
}

@media ( max-width: 64em ) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .container {
        width: 95%;
        max-width: 62rem !important;
        margin: 0 auto !important;
    }
}
@media (min-width: 64.0625em) and (max-width: 80em) {
    .wrapper {
        width: 95%;
        margin: 0 auto;
    }
    .container {
        width: 100%;
        max-width: 79.9375rem;
        margin: 0 auto;
    }
}
@media (min-width: 80.0625em) and (max-width: 85.25em) {
    .wrapper {
        width: 90%;
        margin: 0 auto;
    }
    .container {
        width: 95%;
        max-width: 85.3125rem;
        margin: 0 auto;
    }
}
@media (min-width: 85.3125em) and (max-width: 89.9375em) {
    .wrapper {
        width: 90%;
        margin: 0 auto;
    }
    .container {
        width: 90%;
        max-width: 90rem;
        margin: 0 auto;
    } 
}
@media (min-width: 90em) and (max-width: 95.9375em) {
    .wrapper {
        width: 85%;
        margin: 0 auto;
    }
    .container {
        width: 85%;
        max-width: 96rem;
        margin: 0 auto;
    }
}
@media (min-width: 96em) and (max-width: 105em) {
    .wrapper {
        width: 80%;
        margin: 0 auto;
    }
    .container {
        width: 80%;
        max-width: 105rem;
        margin: 0 auto;
    } 
}
@media (min-width: 105.0625em) {
    .wrapper {
        width: 80%;
        margin: 0 auto;
    }
    .container {
        width: 80%;
        max-width: 105rem;
        margin: 0 auto;
    } 
}
