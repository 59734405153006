.star_review_box {
    margin: 0 auto;
}
ul.star_rating {
    display: inline-block;
    margin-top: .25rem;
    margin-bottom: .5rem;
}

ul.star_rating li {
    display: inline-block;
    margin: 0;
    color: #ffcc00;
}
ul.star_rating li i {
    font-size: 1.25rem;
    color: #ff9c00;
}
.star_review_box span.badge {
    display: inline-block;
    font-size: 1.125rem;
    color: #fff;
    float: none;
    margin-left: .625rem;
    margin-right: 1.75rem;
    line-height: 1.875rem;
    height: 1.875rem;
    padding: 0 .75rem;
    border-radius: .3125rem;
}

.star_review_box .read_reviews {
    display: inline-block;
    font-size: 1.125rem;
    color:blue;
    margin: 0 0 .5rem 0;
}

// Product summary review
.product-aggregate-review {
    margin-top: 2rem;

}
.review-callout {
    width: 100%;
    min-height: 17.4rem;
    text-align: center;
    border-radius: .625rem;
    overflow: hidden;
    margin-bottom: 1rem;
}
.review-callout.green {
    background-color: #32cd32 !important;
}
.review-callout.green .rating-number {
    font-size: 5rem;
    font-weight: 600;
    color: #fff;
    margin-top: 1.5rem;
    display: inline-block;
    margin-bottom: .625rem;
}
.review-callout ul.star_rating {
    display: flex;
    margin-top: .5rem;
    margin-bottom: .8125rem;
    align-items: center;
    justify-content: center;
}
.review-callout ul.star_rating li i {
    margin-right: .625rem;
    color: #ffcc00;
}
.review-callout .black {
    height: 3.75rem;
    margin-top: 2rem;
    display: block;
    width: 100%;
}
.review-callout .black .rating-scale {
    margin: 0;
    line-height: 3.75rem;
    color: #fff;
    font-weight:600;
}
.review-callout.gray {
    background-color: #f3f4f6;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;
    padding: 0 .9375rem;
    border: .0625rem solid #b6bdc7;
}
.review-callout.gray .review-count {
    width: 100%;
    display: block;
    font-size: 2.1875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

// Product detail review
.product-review-container {
    padding: 3rem;
    display: inline-block;
    border-bottom: .0625rem solid #b6bdc7;
    width: 100%;
}
.product-review-container:first-child {
    border-top: .0625rem solid #b6bdc7;
}
.product-review-container .header_review_box {
    margin-bottom: 1.25rem;
    position: relative;
}
.verified_btn {
    background-color: #f3f4f6;
    padding: .5rem 1rem;
    max-width: 18.75rem;
    width: 100%;
    font-size: 1.5rem;
    color: #000;
    display: flex;
    float: right;
    margin-right: .9375rem;
    text-align: center;
    justify-content: center;
    line-height: 2.8125rem;
    border-radius: .3125rem;
}
.verified_btn span {
    width: 2.5625rem;
    height: 2.5625rem;
    display: inline-block;
    margin-right: .9375rem;
}
.product-review-content {
    margin-bottom: 1.875rem;
}
.product-review-content .review-headline {
    font-size: 1.5rem;
    font-weight: 600;
}
.product-review-content .review-author {
    font-weight: 500;
    color: #737373;
    font-size: 1.125rem;
}
.product-review-container p {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 2rem;
}
.product-review-container:last-child {
    padding: 1.875rem 3.125rem 0 3.125rem;
    border-bottom:none !important;
}
.fade-in {
    opacity: 0;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    -ms-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
}
