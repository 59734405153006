html {
    font-family: 'Montserrat', Arial, Verdana, sans-serif;
}

/*
@font-face {
    font-family: "MontFallback";
    ascent-override: 96.8%;
    descent-override: -25.1%;
    line-gap-override: 0%;  
    advance-override: 164.2%;
    src: local("Arial");
}
*/

p {
    font-weight:400;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-rendering: optimizeLegibility;
}
.card .card-content p {
    margin: 1em 0 1em 0;
    line-height: 1.5em;
    font-size: 1.2em;
    font-weight: 400;
}

p.taxonomy {
    color: lighten($primary-color, 10%);
    font-size: 1.125rem;
    font-weight: 600;
}
.small-font-size {
    font-size: $small-font-size;
}
.text-small {
    font-size:.8rem; color:#888;
}
.has-error {
    border: .0625 solid $secondary-color;
}
.help-block  {
    font-size: .875rem;
    color: #0000008c;
    font-weight: 500;
    margin-top: .55rem;
    display: inline-block;
    text-align: right;
    width: 100%;
}
.show-for-sr {
    position: absolute !important;
    width: .0625rem;
    height: .0625rem;
    overflow: hidden;
    clip: rect(0,0,0,0);
}
.clickable:hover {
    cursor:pointer;
}
.list-item-title {
    color:$secondary-color;
    font-weight:500;
}
.bold {
    font-weight: 600;
}
