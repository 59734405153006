.why_buy_section {
    margin-top: 1.25rem;
    background-color:white;
    padding-bottom: .75rem;
}
.flex-item-why-buy {
    display: flex !important;
    flex: 1 !important;
    flex-basis: 50%;
    min-width: 19rem;
}
.why-buy-card {
    padding: 1rem;
    border-radius: .625rem .625rem .625rem .625rem !important;
    overflow: hidden;
    box-shadow: 0 .3125rem 1.875rem rgba(0,0,0,0.25);
    margin: 1.25rem 0 1.25rem 0;
    background-color: white;
}

.whyshop_section {
    padding-bottom: 3rem;
}
.whyshop_section .card-panel {
    min-height: 17.3125rem;
    box-shadow: 0 .5rem 1.875rem 0 rgba(0,0,0,0.05);
    border-radius: .625rem;
    padding-top: 2.875rem;
}
.whyshop_section .card-panel .img {
    height: 8.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whyshop_section .card-panel h4 {
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: 1.625rem;
}
.whyshop_section .row {
    padding: 0 1rem;
}
.payment_option {
    margin-top: 6rem;
}
ul.payment_option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 98%;
    // margin: 100px auto 0;
}
ul.payment_option li {
    flex:0 0 100%;
    max-width: 16.66%;
    text-align: center;
}
ul.payment_option li:first-child{
    text-align: left;
    max-width: 13%;
}
ul.payment_option li:nth-child(5){
    text-align: right;
    max-width: 18%;    
}
ul.payment_option li:last-child{
    text-align: right;
    max-width: 19%;    
}

footer.page-footer {
    position: relative;
	background: url(../graphics/00000002/footer_bg.jpg) top center no-repeat;
	background-size: cover;
	padding-top: 4.75rem;
	padding-bottom: 1.25rem;
}
footer.page-footer .top_footer .footer-logo {
    margin-top: 1rem;
}
footer.page-footer .top_footer .footer-logo img {
    padding-bottom: 2rem;
}
footer.page-footer .top_footer {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
footer.page-footer h5 {
	color: #C82027;
	font-size: 1.375rem;
	margin-bottom: 1.375rem;
}
footer.page-footer li a {
	color: #fff;
	font-size: 1.125rem;
	line-height: 2.5rem;
}
footer.page-footer li a:hover {
	color: #C82027;
}
footer.page-footer .mid_footer .row {
    padding: 2.5rem 0 1.4375rem;
    margin: 1.25rem 0 1.25rem !important;
}
footer.page-footer .mid_footer .row {
    margin-bottom: 0;
    border-top: .125rem solid rgba(200,32,39,.4);
    border-bottom: .125rem solid rgba(200,32,39,.4);
}
footer.page-footer .footer-copyright p {
    font-size: .9375rem;
}
