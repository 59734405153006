.top_menu {
    padding: 1.1875rem 0;
    background-color: white;
}
.top_menu .row {
	margin-bottom: 0;
}
.top_menu .brand-logo {
	margin-top: .25rem;
}
.right_topmenu {
	display: flex;
}
.right_topmenu li {
	padding: 0 1.375rem 0 1.375rem;
}
.right_topmenu li a, .right_topmenu li button {
    font-family: 'Roboto', sans-serif;
	font-size: 1.125rem;
	color: #000;
	font-weight:500;
}
.right_topmenu li a:hover, .right_topmenu li button:hover {
	color: #c82027;
}
.right_topmenu li:last-child {
	padding-right: 0;
}
.dropdown-content{
	top: auto !important;
}
.top_menu .right_topmenu {
	margin: .5rem 0 0;
}
.top_menu .right_topmenu .material-icons {
    display: inherit;
    position: relative;
    line-height: initial;
    top: .375rem;
    color: #c82027;
    font-size: 1.5rem;
    margin-right: .375rem;
}
.top_menu .right_topmenu .material-icons.right {
	margin-right: 0;
    font-size: 1.25rem;
    top: .5625rem;
}
.top_menu .right_topmenu .dropdown-content {
	top: auto !important;
	min-width: 11.875rem;
}
.top_menu .right_topmenu .dropdown-content li>a {
    font-size: 1rem;
    color: black;
    display: block;
    line-height: 1.375rem;
    padding: .875rem 1rem;
}
.top_menu form.search_form {
    max-width: 31.25rem;
    width: 100%;
    margin: 0 auto;
    display: inline-block;
}
.top_menu .input-field, .search_form .input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.top_menu  form.search_form input[type="text"]:not(.browser-default),
nav  form.search_form input[type="text"]:not(.browser-default) {
	margin: 0;
    border: none;
    color: #fff;
    font-weight: 600;
    padding-left: 3rem;
    box-shadow: none!important;
    background: #0d2445 !important;
    border-radius: .375rem;
}
.top_menu  form.search_form .input-field input[type='text'] {
    border-bottom: .0625rem solid $primary-color !important;
    box-shadow: none!important;
}
.top_menu  form.search_form .material-icons, .fa-search { 
    color: #fff;
    line-height: 2.75rem;
    margin-left: .75rem;
    font-size: 2rem;
    width: auto;
}
.medium-and-down-show {
    display:none;
}
@media ( max-width: 48em ) {
    .medium-and-down-show {
        display: inline-block;
    }
    form.mobile_search_form {
        width: 100%;
        display: inline-block;
        margin-top: .25rem;

    }
    input.autocomplete[type="text"]:not(.browser-default) {
        margin: 0;
        height: 2.5rem !important;
        max-height: 2.5rem !important;
        border: none;
        color: #fff;
        font-weight: 600;
        padding-left: 3rem;
        box-shadow: none;
        background: #0d2445 !important;
        border-radius: .375rem;
    }
    i.material-icons.prefix { 
        color: white;
        line-height: 1.875rem;
        margin-left: .75rem;
        font-size: 2rem;
        width: auto;
    }
}
.top_menu input[type=text]:not(.browser-default):focus:not([readonly]) {
	border:none;
	box-shadow: none;
}
.top_menu .input-field .prefix ~ input {
    width: calc(100% - 3rem);
}
.top_menu .input-field{
    margin-top: 0;
}
.right_topmenu li.cart, .right_topmenu li.account_person {
    position: relative;
}
.right_topmenu li.cart:before, .right_topmenu li.account_person:before  {
	content: "|";
    font-size: 1.125rem;
    display: inline-block;
    left: -0.25rem;
    position: absolute;
    top: .25rem;
}
@media ( max-width: 64em ) {
    .right_topmenu li {
        padding: 0 .25rem 0 .25rem;
    }
    .right_topmenu li a {
        font-size: 1rem;
    }
}
@media (min-width: 64.0625em) and (max-width: 80em) {
    .right_topmenu li {
        padding: 0 .25rem 0 .25rem;
    }
    .right_topmenu li a {
        font-size: 1rem;
    }
}
@media (min-width: 80.0625em) and (max-width: 85.25em) {
    .right_topmenu li {
        font-size: .85rem;
        padding: 0 1.25rem 0 1.25rem;
    }
}
@media (min-width: 85.3125em) and (max-width: 89.9375em) {
    .right_topmenu li {
        font-size: .85rem;
        padding: 0 1.25rem 0 1.25rem;
    }
}
