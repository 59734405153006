[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {  
  height: auto;
} 
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }  
}
.image-wrap-container img {
  margin: 1rem;
}
.image-wrap-container h2 {
  padding-top: 1.5rem !important;
}
.image-wrap-container p {
  font-size: 1.125rem;
}
#secproddescrip img {
  padding: 0 1rem 0 0;
}
@media only screen and (max-width: 48em), (max-width: 37.5em) {
  .image-wrap-container img {
      padding-bottom: 2rem;
  }
}