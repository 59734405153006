nav {
	background-color: #c82027;
}
nav ul li {
    float: none;
    text-align: left;
}

nav ul a {
    font-family: 'Roboto', sans-serif;
	font-size: .8125rem;
    display: block;
	padding: 0 .25rem;
}

.megamenu li img {
    position:relative;
    top: .375rem;
}
.mobile-nav i {
    height: 3.125rem !important;
	line-height: 3.125rem !important;
}

.megamenu .dropdown-content a {
    color: $primary-color !important;
    font-weight: 500;
}
.megamenu-content {
    width: 320px !important;
    box-shadow: 0 8px 8px rgba(0,0,0,0.25);
}

.megamenu-content ul li {
    float: none !important;
    text-align: left;
}
@media (min-width: 64.0625em) and (max-width: 80em) {
    nav ul a {
        font-size: .9rem;
        padding: 0 .125rem;
    } 
}
@media (min-width: 80.0625em) and (max-width: 85.25em) {
    nav ul a {
        font-size: 1rem;
        padding: 0 .375rem;
    }
}
@media (min-width: 85.3125em) and (max-width: 89.9375em) {
    nav ul a {
        font-size: 1.0625rem;
        padding: 0 .375rem;
    } 
}
@media (min-width: 90em) and (max-width: 95.9375em) {
    nav ul a {
        font-size: 1.125rem;
        padding: 0 .25rem;
    } 
}
@media (min-width: 96em) and (max-width: 105em) {
    nav ul a {
        font-size: 1.125rem;
        padding: 0 .25rem;
    } 
}
@media (min-width: 105.0625em) {
    nav ul a {
        font-size: 1.25rem;
        padding: 0 .875rem;
    } 
}


