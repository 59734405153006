.card-panel {
    flex: 1;
}

.card {
	border-radius: .625rem .625rem 0 0 !important;
    overflow: hidden;
    box-shadow: 0 .375rem 1.875rem rgba(0,0,0,0.25);
    margin: 1.25rem 0 0;
    background-color: white;
}
@media ( max-width: 64em ) {
    .card {
        box-shadow: none;
    }

}

.card-head {
    font-size: 1.25rem;
    background-color: $primary-color;
    color: white;
    padding: 1rem 0 1rem 1.875rem;
    font-weight: 500 !important;
}

.card .card-image img {
	max-height: 15.625rem;
	height: 15.625rem;
    object-fit: contain;
    border-radius: .625rem .625rem 0 0 !important;
}

h2.card-title {
    color: lighten($primary-color, 10%) !important;
    font-size: 1.5rem;
    font-weight: 600 !important;
    margin-top: 0;
    margin-bottom: .625rem !important;
}
.card .card-content p {
    margin: 1em 0 1em 0;
    line-height: 1.5em;
    font-size: 1.125em;
    font-weight: 400;
    color: black !important;
}