.home_banner, .category_banner, .product_banner {
    position: relative;
}

.home_banner h1, .category_banner h1 {
	font-size: 2.87rem;
	text-transform: uppercase;
    position: absolute;
    top: -1rem;
    left: 10rem;
    font-weight: 400;
}
.home_banner h1 strong {
	display: block;
}

.home_banner p {
    position: absolute;
    top: 10rem;
    max-width: 25rem;
    left: 10rem;
	margin-bottom: 0;
}
.home_banner_text {
    margin-top: 0 !important;
}
.shop-star {    
    position: absolute;
    top: 27rem;
    left: 10rem;
    max-width: 15.625rem;
}
.shop-star ul{
	display: flex;
	flex-wrap: wrap;
	margin-top: .375rem;
}
.shop-star ul li {       
    color: #fbbd00;
    margin-right: .375rem;
}
.shop-star ul li.review-count {
	font-size: 1.125rem;
	margin-left: .625rem; 
	color: #000000;
}
@media ( max-width: 37.5em ) {
    .home_banner h1, .category_banner h1 {
        font-size: 1.5rem;
        top: -1.25rem;
        left: 1.25rem;
        max-width: 12rem;
    }
    .home_banner_text {
        font-weight: 400;
        margin-top: -1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-rendering: optimizeLegibility;
    }
}
@media ( min-width: 37.5625em ) and ( max-width: 64em ) {
    .home_banner h1, .category_banner h1 {
        font-size: 2.5rem;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 5rem;
    }
    .home_banner p {
        top: 8rem; left: 5rem; font-size: 1.125rem; max-width: 20rem;
    }
    .header {
        font-size: 3rem;
    }
    .shop-star {
        top: 19rem;
        left: 44rem;
    }
}
@media (min-width: 64.0625em) and (max-width: 80em) {
    .home_banner h1, .category_banner h1 {
        font-size: 2.875rem;
        position: absolute;
        top: -.5rem;
        left: 5rem;
    }
    .home_banner p {
        top: 10rem; left: 5rem; font-size: 1.125rem; max-width: 24rem;        
    }
    .header {
        font-size: 2.875rem;
    }
    .shop-star {
        top: 26rem;
        left: 5rem;
    }
}
@media (min-width: 80.0625em) and (max-width: 96em) {
    .home_banner h1, .category_banner h1 {
        font-size: 3rem;
        position: absolute;
        top: 0;
        left: 5rem;
    }
    .home_banner p {
        top: 10rem; left:5rem; font-size: 1.25rem; max-width: 26rem;        
    }
    .header {
        font-size: 3rem;
    }
    .shop-star {
        top: 28rem;
        left: 5rem;
    }
}
@media ( min-width: 96.0625em ) {
    .home_banner h1, .category_banner h1 {
        font-size: 3rem;
        position: absolute;
        top: 0;
        left: 12rem;
    }
    .home_banner p {
        top: 10rem; left: 12rem; font-size: 1.25rem; max-width: 26rem;        
    }
    .header {
        font-size: 3rem;
    }
    .shop-star {
        top: 28rem;
        left: 12rem;
    }
}

.aboutusbanner {
    position: relative;
    margin-top:1.25rem;
}

.aboutusbanner h2 {
	font-size: 2.87rem;
	text-transform: uppercase;
    position: absolute;
    top: 0;
    margin-left: 1.5rem;
    font-weight: 400;
}

.aboutusbanner p {
    margin-left: 1.5rem;
    margin-bottom: 0;
    color: #000000;
    position: absolute;
    top: 9.5rem;
    max-width: 30rem;
}

@media ( max-width: 62em ) {
    .aboutusbanner h2 {
        top: -.5rem;
        font-size: 2rem;
    }
    .aboutusbanner p  {
        top: 6rem;
        font-size: 1rem;
        max-width: 20rem;
    }
}

@media ( max-width: 37.5em ) {
    .aboutusbanner h2 {
        font-size: 1.75rem;
        position: absolute;
        top: -1.5rem;
        max-width: 20rem;
    }
    .aboutusbanner h2 strong {
        display: block;
    }
    .aboutusbanner_text {
        background-color: white;
        padding: .6rem;
        margin-left: .8rem;
        margin-right: .8rem;
        font-weight: 400;
        margin-top: -1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-rendering: optimizeLegibility;
    }
}

.section_testimonial {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}
.flex-testimonial {
    display: flex !important;
    flex: 1 !important;
    flex-basis: 32%;
    min-width: 19rem;
    margin: .5rem; 
}
.testimonial-quote {
    position: absolute;
    float:left;
    font-size: 3.125rem;
    color: #c62026;
    width: 5.375rem;
    height: 3.9375rem;
    top: 1.125rem;
    left: 0;
}
.testimonial_person {
    display: block;
    width: 100%;
    position: relative;
}
.testimonial_person img {
    width: 6.25rem;
    height: 6.25rem;
    max-width: 6.25rem;
    border: .25rem solid #fff;
    box-shadow: 0 0 1.875rem rgba(0, 0, 0, .1);
}
.testimonial_person img {
    margin-left: 2.5rem;
    object-fit: cover;
}
.testimonial_headline {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: .25rem;
    margin-top: 1.25rem;
}