.flex-grid {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-items: stretch !important;
}

.flexbox-item {
    display: flex !important;
    flex: 1 !important;
    margin: .5rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
}

.flexbox-item-tab {
    display: flex !important;
    margin: .5rem;
    flex-grow: 1;
    flex-shrink: 0;
    align-self: stretch;
}

.flexbox-basis-66 {
    flex-basis: 66%;
    min-width: 65%;
    width: 65%;
}
.flexbox-basis-50 {
    flex-basis: 48% !important;
    min-width: 48% !important;
}
.flexbox-basis-33 {
    flex-basis: 30% !important;
    width: 32% !important;
    min-width: 30% !important;
}
.flexbox-basis-25 {
    flex-basis: 23%;
    width: 23%;
    min-width: 23%;
}

@media only screen and (min-width: 62.0625em) {
    .flexbox-basis-25 {
        flex-basis: 23%;
        width: 23%;
        min-width: 23%;
    } 
}

@media only screen and (max-width: 62em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 50%;
        width: 50%;
        min-width: 50%;
    } 
}
@media only screen and (max-width: 48em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 48%;
        width: 48%;
        min-width: 48%;
    } 
}

@media only screen and (max-width: 37.5em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-item {
        margin: .5rem 0 0 0;
    }
}

@media screen and (min-width: 24.5625em) and (max-width: 47.9375em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-item {
        margin: .5rem 0 0 0;
    }
}