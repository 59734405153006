.btn {
    font-family: 'Roboto', sans-serif;
}
.btn, .btn-large {
    font-weight: 500;
}
.btn-large-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.btn-link {
    border-radius: 0;
    color: lighten($secondary-color, 0%);
    font-weight: normal;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent;
}

.btn-link:hover, .btn-link:focus {
    background-color: transparent;
    color: #23527c;
    text-decoration: underline;
}

.btn-link[disabled]:hover, fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
    color: #777;
    text-decoration: none;
}
.btn-link-blue {
    font-size: 1rem;
    font-weight: 600;    
    color: #23527c;
    background-color: transparent;    
    border-radius: 0;
    border-color: transparent;
    box-shadow: none;   
}
.btn-link-blue:hover {
    text-decoration: underline;
}
.btn-gtm-rp {
    background-color: transparent;
    border-color: transparent;
}

.btn-alternate {
    background-color: #eee;
    color: #333;
}
  
.btn-alternate:hover, .btn-alternate:active {
    background-color:  scale-color(#eee, $lightness: -10%);
    color: #222;
}

.btn_outline {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: .0625rem solid transparent;
    padding: .75rem 2.1875rem;
    font-size: 1.25rem;
    border-radius: .3125rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn_gray {
    border: .0625rem solid rgba(13, 36, 69, 31%);
    font-weight: 500;
    color: #737373;
}

.btn_gray:hover {
    border: .0625rem solid rgba(200, 32, 39, 31%);
    color: #c82027;
}

.btn-cart {
    width: 100%;
}

.btn-tagline {
    margin-top:.5rem;
    font-style:italic;
}

.anchor-button {
    text-align: center !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color:blue !important;
}
a.anchor-button:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.password-link {
    font-size:.8rem;
}
.password-link:hover {
    text-decoration: underline;
}