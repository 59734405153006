// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$red: (
  "base":       #F44336,
  "lighten-5":  #FFEBEE,
  "lighten-4":  #FFCDD2,
  "lighten-3":  #EF9A9A,
  "lighten-2":  #E57373,
  "lighten-1":  #EF5350,
  "darken-1":   #E53935,
  "darken-2":   #D32F2F,
  "darken-3":   #C62828,
  "darken-4":   #B71C1C,
  "accent-1":    #FF8A80,
  "accent-2":    #FF5252,
  "accent-3":    #FF1744,
  "accent-4":    #D50000
);

$blue: (
  "base":       #2196F3,
  "lighten-5":  #E3F2FD,
  "lighten-4":  #BBDEFB,
  "lighten-3":  #90CAF9,
  "lighten-2":  #64B5F6,
  "lighten-1":  #42A5F5,
  "darken-1":   #1E88E5,
  "darken-2":   #1976D2,
  "darken-3":   #1565C0,
  "darken-4":   #0D47A1,
  "accent-1":    #82B1FF,
  "accent-2":    #448AFF,
  "accent-3":    #2979FF,
  "accent-4":    #2962FF
);

$green: (
  "base":       #4CAF50,
  "lighten-5":  #E8F5E9,
  "lighten-4":  #C8E6C9,
  "lighten-3":  #A5D6A7,
  "lighten-2":  #81C784,
  "lighten-1":  #66BB6A,
  "darken-1":   #010201,
  "darken-2":   #388E3C,
  "darken-3":   #2E7D32,
  "darken-4":   #1B5E20,
  "accent-1":    #B9F6CA,
  "accent-2":    #69F0AE,
  "accent-3":    #00E676,
  "accent-4":    #00C853
);

$grey: (
  "base":       #9e9e9e,
  "lighten-5":  #fafafa,
  "lighten-4":  #f5f5f5,
  "lighten-3":  #eeeeee,
  "lighten-2":  #e0e0e0,
  "lighten-1":  #bdbdbd,
  "darken-1":   #757575,
  "darken-2":   #616161,
  "darken-3":   #424242,
  "darken-4":   #212121
);

$as-red: (
  "base":       #c62026,
  "lighten-5":  #e66468,
  "lighten-4":  #e35358,
  "lighten-3":  #e04147,
  "lighten-2":  #dd3036,
  "lighten-1":  #d72329,
  "darken-1":   #b71e23,
  "darken-2":   #ad1c20,
  "darken-3":   #9b191e,
  "darken-4":   #8d171b,
);

$as-blue: (
  "base":       #0d2445,
  "lighten-5":  #bfcdfa,
  "lighten-4":  #909fc9,
  "lighten-3":  #1c4d92,
  "lighten-2":  #64739b,
  "lighten-1":  #39496e,
  "darken-1":   #0c2240,
  "darken-2":   #0b1f3b,
  "darken-3":   #0a1c36,
  "darken-4":   #091a31,
);

$shades: (
  "black":        #000000,
  "white":        #FFFFFF,
  "transparent":  transparent
);

$colors: (
  "red": $red,
  "blue": $blue,
  "green": $green,
  "grey": $grey,
  "as-red": $as-red,
  "as-blue": $as-blue,
  "shades": $shades
) !default;


// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);
    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }
  @warn "Unknown `#{$color}` - `#{$type}` in $colors.";
  @return null;
}
