.mm10_login_title_container {
    position: relative;
    display: block;
    margin-bottom: 1rem;
    color: #494a53;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.login-header {
    font-size: 3rem;
    line-height: 110%;
    font-weight: 500;
    color: black;
    margin: 1.94667rem 0 1.168rem 0;
}

.login-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: #757575;
}

// ordl
.fa.fa-print, .mi-print {
    color: inherit;
    font-size: inherit;
}

@media screen and (max-width: 37.5em){
    .g-recaptcha {
        transform:scale(0.77);
        -webkit-transform:scale(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
    }
}

.quote {
    padding: 3rem;
    font-size: 2rem !important;
    line-height: 2.5rem;
    font-weight: 400;
    text-transform : uppercase;
}
.quote strong {
    font-weight: 700;
    display: block;
}

.recent-blogs {
    display: inline-block;
    padding-bottom: 1rem !important;
    border: 1px solid red;

}