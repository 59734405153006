.input-field input[type=text], .input-field input[type=email], .input-field input[type=tel], .input-field input[type=password] {
    background: transparent !important;
    font-size: 1.125rem;
    font-weight: 500;
    padding-left: .9375rem;
    border-bottom: .0625rem solid $secondary-color !important;
    box-shadow: none!important;
    height: 3.75rem;
    max-width: calc(100% - .9375rem);
    display: inline-block;
}
.input-field input[type=text].valid, .input-field input[type=email]:valid, .input-field input[type=tel]:valid, .input-field input[type=password]:valid, .input-field textarea:valid  {
    border-bottom: .0625rem solid #43a047 !important;
    box-shadow: 0 .0625rem 0 0 #43a047 !important;
}
.input-field label {
    padding-left: .75rem;
    font-size: 1.125rem;
    color: #737373;
    font-weight: 500;
}
.input-field input[type=text].valid + label, .input-field input[type=email]:valid + label, .input-field input[type=tel]:valid + label {
    color: #43a047;
}
.input-field input[type=text], .input-field input[type=text]:focus {
    border-bottom: 1px solid black !important;
    box-shadow: 0 1px 0 0 black !important;
    border-radius: .25rem !important;
 }
.input-field textarea {
    background: #f3f4f6 !important;
    border: none !important;
    box-shadow: none!important;
    height: 5.625rem !important;
    border-radius: .3125rem !important;
    margin-bottom: 0;
    padding-left: 1.125rem;
    font-size: 1.125rem;
}
.input-field textarea:focus {
    margin-top: 0;
    border: none !important;
}

#shipping_fields, #bill-to-body, .product-block, .login-box {
    overflow: visible;
}

.select-dropdown {
    padding-left: .625rem !important;
    font-size: 1.125rem !important;
    width: calc(100% - .625rem);
    color: #737373;
    font-weight: 500;
}
.select-wrapper svg.caret {
    z-index: 5;
    width: 2rem;
    height: 2rem;
    margin-right: 1.25rem;
    fill: $secondary-color;
}
.input-field .select-dropdown {
    width: calc(100% - .625rem);
}

.input-group {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: .625rem;
}

.qty-box {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: .625rem;
    max-width: 9.375rem;
}

.qty-box .input-group-btn {
    position: absolute;
    right: .625rem;
    top: 0;
    width: 1.25rem;
    height: 3.75rem;
}

.qty-box .input-group-btn .btn-number {
    width: 1.25rem;
    height: 1.25rem;
    background: #fff;
    border: .0625rem solid #aeb5c1;
    border-radius: .1875rem;
    line-height: .875rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0;
    position: absolute;
    top: .5rem;
}

.qty-box .input-group-btn .quantity-left-minus {
    top: 2.125rem;
}
.qty-box #quantity {
    background-color: #ffffff;
    height: 3.75rem;
    border: none;
    padding-left: 1.25rem;
    font-weight: 500;
    font-size: 1.375rem;
    border-radius: .3125rem;
    max-width: calc(100% - 1.25rem);
    margin-bottom: 0;
}
.product-quantity .qty-box {
    border: .0625rem solid #aeb5c1;
    border-radius: .3125rem;
}

.qty-box .input-group-btn .btn-number:hover,
.qty-box .input-group-btn .btn-number:focus {
    border-color: $secondary-color;
    color: $secondary-color;
}
@media screen and (max-width: 25.6875em) {
    .qty-box {
        display:inline-block;
        text-align: right;        
    }    
}
.radio_box .prompt {
    font-size: 1.5rem;
    margin-bottom: 1.5625rem;
    margin-top: 0;
    font-weight: 500;
}
.radio_box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.radio_box label {
    font-size: 1.125rem;
    margin-right: .625rem;
    font-weight: 500;
}
